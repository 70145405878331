<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-dialog :visible.sync="dialogVisible_verify" custom-class="cus_dialog cus_dialogFullHeight" width="90%"
      :close-on-click-modal="false" @close="onClose">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 100%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="110px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="入库单号:">{{ EditItem.rk_num }}</el-form-item>
                <el-form-item v-if="EditItem.sg_num" label="单据日期:">{{
                  EditItem.sg_cdate ? EditItem.sg_cdate.substr(0, 10) : '-'
                  }}
                </el-form-item>
                <el-form-item label="申请人:">{{ EditItem.username }}</el-form-item>

                <el-form-item label="入库仓库:" prop="zc_storeid">{{ EditItem.storename }}</el-form-item>

                <el-form-item label="仓库管理员:">{{ EditItem.store_admin_name }}</el-form-item>

                <el-form-item label="金额合计(元):">{{ EditItem.howmuch_rk }}</el-form-item>

                <el-form-item label="入库备注:">
                  <el-input type="textarea" :rows="5" :value="EditItem.rk_remark || '-'" size="mini"
                    disabled></el-input>
                </el-form-item>

                <el-form-item label="相关图片:">
                  <div class="flexStart">
                    <div v-if="EditItem.files && EditItem.files.length > 0" class="updimage"
                      v-for="(f, idx) in EditItem.files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;cursor:pointer;" />
                    </div>

                    <div v-if="!EditItem.files || EditItem.files.length == 0" class="">-</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-table ref="mySGtable" :data="EditItem.hc_list" height="100%" :row-key="(row) => { return row.id; }" stripe
            border style="width:fit-content;width:100%;">

            <el-table-column :key="Math.random()" type="index" label="序号" width="55" />
            <el-table-column :key="Math.random()" prop="title" label="耗材名称" width="200" :show-overflow-tooltip="true">
              <!--                  <template slot-scope="scope">-->
              <!--                    <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{ scope.row.title }}-->
              <!--                    </div>-->
              <!--                  </template>-->
            </el-table-column>

            <!-- <el-table-column :key="Math.random()" prop="typename1" label="耗材分类" width="120"
                  :show-overflow-tooltip="true" /> -->
            <el-table-column :key="Math.random()" prop="pinpai" label="耗材品牌" />
            <el-table-column :key="Math.random()" prop="xinghao" label="耗材型号" :show-overflow-tooltip="true" />
            <el-table-column :key="Math.random()" prop="cgJine" width="150" :show-overflow-tooltip="true"
              align="center">
              <template slot="header">
                <span style="color: #F56C6C">*采购单价(元)</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ scope.row.cgJine }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" prop="cgNum" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">*采购数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ scope.row.cgNum }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" prop="cgTotal" label="采购总价(元)" :show-overflow-tooltip="true"
              width="100" />
            <el-table-column :key="Math.random()" prop="jine" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">*入库单价(元)</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ scope.row.rkJine }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" prop="jine" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">*入库数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ scope.row.rkNum }}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" prop="rkTotal" label="入库总价(元)" :show-overflow-tooltip="true"
              width="100" />

            <el-table-column :key="Math.random()" prop="remark" label="入库备注" :show-overflow-tooltip="true" width="200">
              <template slot-scope="scope">
                <div class="">{{ scope.row.rk_remark || '-' }}</div>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="dialogTitle == '申购单入库'" prop="user_remark" label="备注"
              :show-overflow-tooltip="true" width="200">
              <template slot-scope="scope">
                <div>{{ scope.row.user_remark || '-' }}</div>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="dialogTitle == '申购单入库'" prop="img" label="图片" width="200">


              <template slot-scope="scope">

                <div class="flexStart">
                  <div v-if="scope.row.img" class="updimage"
                    style="position: relative;margin-top:10px;width: 100px;height: 100px;">
                    <img :src="scope.row.img" @click="handlePictureCardPreview(scope.row.img)"
                      style="width: 100px;height: 100px;cursor:pointer;">
                  </div>
                  <div v-else class="">-</div>

                </div>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="dialogTitle == '申购单入库'" prop="externalLinks" label="链接"
              width="250">
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.links && scope.row.links.length > 0" v-for="(item, index) in scope.row.links">
                    <el-link :href="item" target="_blank" type="primary">{{ item }}</el-link>
                  </div>
                  <div v-if="!scope.row.links || scope.row.links.length == 0">-</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div slot="footer">
        <el-form label-position="left" label-width="90px" style="text-align:left;">
          <el-form-item label="入库意见：">
            <el-input v-model="EditItem.checkReason"  placeholder="驳回时请填写驳回原因"></el-input>
          </el-form-item>
          <el-form-item label="附件：">
            <!--            <el-upload action="" :show-file-list="false" :http-request="uploadOss" :on-success="uploadCover" name="image">
                          <div v-if="!sp_file" style="white-space: nowrap; color: skyblue;margin-right: 20px; cursor: pointer;">
                            上传<i class="el-icon-upload"></i>
                          </div>
                          <div v-else style="white-space: nowrap; color: skyblue;margin-right: 20px; cursor: pointer;">
                            {{sp_file.fileName}}
                          </div>
                        </el-upload>-->
            <el-upload action="" ref="uploadRef" list-type="picture-card" :http-request="uploadOss"
              :on-success="uploadCover" name="image">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
          <el-button type="danger" @click="onVerify(2)" size="small">审核驳回</el-button>
          <el-button type="success" @click="onVerify(1)" size="small">审核通过入库</el-button>
          <el-button type="primary" @click="onClose" plain size="small">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import processviewmini from "../../../com/processviewmini.vue"

export default {
  components: {
    processviewmini,
  },
  data() {
    return {
      //--审批流程用----
      process_code: "hcsg",
      process_id: 0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      disabled: false,
      files: [],
      arr_json: [],
      icansp: false,
      sp_file: [],
      dialogImageUrl: '',
      dialogVisible: false,
    }
  },
  props: ['dialogVisible_verify', 'dialogTitle', 'EditItem'],
  mounted() {
    console.log(174, this.EditItem)
  },
  methods: {
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    handlePictureCardPreview(file) {
      //console.log(this.$parent)
      //this.$$parent(this, 'handlePictureCardPreview', src)
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onClose() {
      this.$$parent(this, 'dialogVisible_verify', false)
    },
    onVerify(status) { //Status = 1通过 =2驳回
      let _this = this
      if (status == 2 && !this.EditItem.checkReason) {
        _this.$message.error(`请填写驳回原因~`)
        return
      }
      this.$confirm(`是否确认${status == 1 ? '通过' : '驳回'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/get_sign_pic").then(res => {
          if (res.data && res.data.handsign) {
            this.$http.post("/api/sx_hc_rk_check", {
              id: _this.EditItem.id,
              sp_status: status,
              sp_remark: _this.EditItem.checkReason,
              ck_handsign: res.data.handsign,
              sp_file: this.sp_file ? JSON.stringify(this.sp_file) : ""
            }).then(res => {
              this.$message({
                type: 'success',
                message: `${status == 1 ? '入库' : '驳回'}操作成功！`
              });
              this.onClose()
              _this.$parent.$refs.SXRukuTable.getList()
            })
          } else {
            this.$message.error("请先在小程序设置个人签名")
            return
          }
        })
      }).catch(() => {

      });

    },
    uploadCover(e,file) {
      e.uid = file.uid
      this.sp_file.push(e)
    },
    handleRemove(file, fileList) {
      if (fileList) return
      const {uploadFiles} = this.$refs.uploadRef
      uploadFiles.splice(uploadFiles.findIndex(item => item.uid === file.uid), 1)
      this.sp_file.splice(this.sp_file.findIndex(item => item.uid === file.uid), 1)
    }
  }
}
</script>
